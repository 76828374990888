<template>
    <app-layout>
        <div>
            <page-header :title="__('assets.activation.headline')">
                <p>{{ __('assets.activation.description') }}</p>
                <filter-bar
                    :is-processing="isProcessing"
                    @change="updateFilter"
                />
            </page-header>
            <single-column-table-view
                v-if="isSmallScreen"
                :fields="visibleFields"
                :items="transformedAssets"
                :total-assets-count="totalAssets"
                @row-clicked="showDetails"
            >
                <template #header-row="{ item }">
                    <div class="d-flex justify-content-end w-100">
                        <badge
                            color="primary"
                            style="width: 25px; height: 25px; line-height: 25px"
                            @click.stop="openQrCodeScanner(item)"
                        >
                            <icon
                                name="cil-qr-code"
                                style="color: #fff; shape-rendering: crispEdges"
                            />
                        </badge>
                    </div>
                </template>
            </single-column-table-view>
            <div v-else>
                <div class="d-flex justify-content-end">
                    <small class="text-muted"
                        >{{
                            __('assets.overview.results.count', {
                                assets_count: `${transformedAssets.length}/${totalAssets}`,
                            })
                        }}
                    </small>
                </div>
                <data-table
                    v-if="assets"
                    :actions="actions"
                    :columns="visibleFields"
                    :default-sorting="{ column: 'type', asc: true }"
                    :items="transformedAssets"
                    :no-items-view="noItemsView"
                    :sticky-header="{ top: '182px' }"
                    @row-clicked="showDetails"
                    @update:sorter-value="updateSorting($event)"
                >
                    <template #airport="{ item }">
                        <td>
                            <span
                                v-c-tooltip="{
                                    content: item.airport_name,
                                    popperOptions: {
                                        positionFixed: true,
                                    },
                                }"
                            >
                                {{ item.airport }}
                            </span>
                        </td>
                    </template>

                    <template #location="{ item }">
                        <td>
                            {{ item.location }}
                        </td>
                    </template>

                    <template #type="{ item }">
                        <td>
                            {{ item.type }}
                        </td>
                    </template>

                    <template #designation="{ item }">
                        <td>
                            <div>
                                {{ item.designation }}
                            </div>
                            <small class="text-nowrap text-dark"
                                >{{ __('assets.overview.cell.serial_number') }}:
                                {{ item.serialNumber }}</small
                            >
                        </td>
                    </template>

                    <template #manufacturer="{ item }">
                        <td>
                            {{ item.manufacturer }}
                        </td>
                    </template>
                </data-table>
            </div>
        </div>
        <pagination :paginator="assets" :query="activeQueryParams" />
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import PetitecIdScanner, {
    PetiteCIdScannerType,
} from '@/Components/PetitecIdScanner.vue';

import FilterBar from '@/Components/FilterBar.vue';
import IssueBadge from '@/Components/IssueBadge.vue';
import StatusBadge from '@/Components/StatusBadge.vue';
import StatusCheckDate from '@/Components/StatusCheckDate.vue';
import BatchJobs from '@/Pages/Assets/BatchJobs/BatchJobs.vue';
import { debounce } from 'lodash';
import { removeEmptyProperties } from '@/utils';
import OnboardedAssetIssue from '@/Pages/Assets/OnboardedAssetIssue.vue';
import WorkOrderBadge from '@/Components/WorkorderBadge.vue';
import DataTable from '@/Components/DataTable.vue';
import { eventBus, events } from '@/eventBus.js';
import PageTitle from '@/Components/PageTitle.vue';
import PageHeader from '@/Components/PageHeader.vue';
import LocationFilter from '@/Pages/Dashboard/Components/LocationFilter.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import SingleColumnTableView from '@/Pages/SingleColumnTableView.vue';
import Badge from '@/Components/Badge.vue';

export default {
    components: {
        Badge,
        SingleColumnTableView,
        CollapsableFilterBar,
        LocationFilter,
        PageHeader,
        PageTitle,
        DataTable,
        OnboardedAssetIssue,
        WorkOrderBadge,
        BatchJobs,
        AppLayout,
        JetButton,
        Pagination,
        PetitecIdScanner,
        IssueBadge,
        FilterBar,
        StatusBadge,
        StatusCheckDate,
    },

    data() {
        return {
            activeQueryParams: { column: 'status', asc: false },
            isProcessing: false,
            allFields: [
                {
                    key: 'designation',
                    label: this.__('assets.overview.cell.designation'),
                    _style: 'width: 20%',
                },
                {
                    key: 'type',
                    label: this.__('assets.overview.cell.type'),
                    _style: 'width: 90px',
                },
                {
                    key: 'airport',
                    label: this.__('assets.overview.cell.airport'),
                    _style: 'width: 90px',
                },
                {
                    key: 'location',
                    label: this.__('assets.overview.cell.location'),
                    _style: 'width: 15%;white-space: nowrap;',
                },
                {
                    key: 'manufacturer',
                    label: this.__('assets.overview.cell.manufacturer'),
                },
            ],
            visibleFields: [],
            noItemsView: {
                noItems: this.__('assets.overview.results.empty'),
            },
            isSearching: false,
            isPetitecIdScannerVisible: false,
            selectedAsset: null,
            actions: [
                {
                    permissions: ['asset.update'],
                    label: this.__('assets.overview.scanner.title'),
                    type: 'primary',
                    click: this.openQrCodeScanner,
                },
            ],
        };
    },

    computed: {
        assets() {
            return this.$page.props.assets;
        },
        totalAssets() {
            return this.assets.meta.total;
        },
        hasCameraSupport() {
            return !!navigator.mediaDevices;
        },
        transformedAssets() {
            const notAvailable = this.__('general.not_available');

            return this.assets.data.map((asset) => ({
                id: asset.id,
                type: asset.type.value || notAvailable,
                designation: asset.designation
                    ? asset.designation.label
                    : notAvailable,
                manufacturer: asset.manufacturer
                    ? asset.manufacturer.name
                    : notAvailable,
                petitec_identifier: asset.petitec_identifier,
                serialNumber: asset.serialNumber || notAvailable,
                airport:
                    asset.location.physical_location.iata_code || notAvailable,

                airport_name:
                    asset.location.physical_location.name || notAvailable,
                location: this.getLocationName(asset.location),
            }));
        },
        defaultSorting() {
            return { column: 'type', asc: true };
        },
        activeQueryParamsWithoutSorting() {
            const params = Object.assign({}, this.activeQueryParams);
            delete params.sort;
            return params;
        },
    },

    methods: {
        getLocationName(location) {
            if (!location) {
                return this.__('general.not_available');
            }

            return [location.name]
                .reduce(
                    (unique, item) =>
                        unique.includes(item) ? unique : [...unique, item],
                    [],
                )
                .filter((item) => item)
                .join(' - ');
        },
        showDetails(asset) {
            this.$inertia.visit(this.route('assets.show', asset.id));
        },
        openQrCodeScanner(item) {
            eventBus.$emit(events.openQrCodeScanner, {
                assetId: item.id,
                type: PetiteCIdScannerType.attachPetiteCId,
            });
        },
        loadAssets() {
            const { sort } = this.activeQueryParams;

            let queryParams = Object.assign(
                {},
                removeEmptyProperties(this.activeQueryParams),
                {
                    sort: this.transformSortToQuery(sort),
                },
            );

            this.$inertia.get(
                this.route('assets.activation', queryParams),
                {},
                {
                    only: ['assets'],
                    preserveState: true,
                    preserveScroll: true,
                    onBefore: () => {
                        this.isProcessing = true;
                    },
                    onFinish: () => {
                        this.updateTable();
                        this.isProcessing = false;
                    },
                },
            );
        },
        updateTable() {
            this.visibleFields = this.allFields.filter((field) => {
                switch (field.key) {
                    case 'airport':
                        return this.$page.props.locations.data.length > 1;
                    default:
                        return true;
                }
            });
        },
        updateFilter: debounce(function (filters) {
            this.activeQueryParams = { ...filters, sort: this.defaultSorting };
            this.loadAssets();
        }, 300),
        updateSorting(event) {
            this.activeQueryParams.sort = event ?? undefined;
            this.loadAssets();
        },
        transformSortToQuery(sort) {
            if (!sort) {
                return undefined;
            }

            let sortDirection = sort.asc ? 'asc' : 'desc';
            return `${sort.column}.${sortDirection}`;
        },
        transformQueryToSort(sort) {
            if (sort) {
                const parts = sort.split('.');
                if (parts.length === 2) {
                    return {
                        column: parts[0],
                        asc: parts[1] === 'asc',
                    };
                }
            }

            return this.defaultSorting;
        },
        startPetitecScannerIfNecessary() {
            const parts = window.location.hash.split('#assign-petitec-id=');

            if (parts.length === 2) {
                this.openQrCodeScanner({ id: parts[1] });
            }
        },
    },
    mounted() {
        const { search, airports, types, sort } = this.route().params;

        this.activeQueryParams = {
            search,
            airports,
            types,
            sort: this.transformQueryToSort(sort),
        };

        this.updateTable();

        window.addEventListener(
            'hashchange',
            this.startPetitecScannerIfNecessary,
        );
    },
    beforeDestroy() {
        window.removeEventListener(
            'hashchange',
            this.startPetitecScannerIfNecessary,
        );
    },
};
</script>
