<template>
    <dropdown
        v-if="languages.length > 1"
        v-c-tooltip="{
            content: __('navigation.language'),
            placement: 'bottom',
        }"
        :caret="false"
        variant="nav-item"
    >
        <template #toggler>
            <span>{{ current.toUpperCase() }}</span>
        </template>
        <dropdown-item
            v-for="lang in languages"
            :key="lang.value"
            :href="$route('language', lang.value)"
            v-text="lang.label"
        />
    </dropdown>
</template>
<script>
import { getLabelForLocale } from '@/Utils/getLabelForLocale';
import Dropdown from '@/Components/Dropdown.vue';
import DropdownItem from '@/Components/DropdownItem.vue';

getLabelForLocale;

export default {
    name: 'LanguageSelector',
    components: { DropdownItem, Dropdown },
    computed: {
        current() {
            return this.currentLocale;
        },
        languages() {
            return (
                this.$page.props.supported_locales?.map((locale) => ({
                    value: locale,
                    label: getLabelForLocale(locale),
                })) || []
            );
        },
    },
};
</script>
<style scoped>
:deep(.dropdown-menu) {
    min-width: auto;
    overflow: hidden;
}

:deep(.dropdown-item) {
    min-width: auto !important;
}
</style>
