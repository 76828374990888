<template>
    <dropdown
        v-c-tooltip="{ content: __('navigation.profile'), placement: 'bottom' }"
        :caret="false"
        variant="nav-item"
    >
        <template #toggler>
            <div class="c-avatar">
                <icon icon="cil-user" />
            </div>
        </template>
        <dropdown-item :href="$route('profile.show')">
            <icon icon="cil-user" />
            {{ __('navigation.profile') }}
        </dropdown-item>
        <dropdown-item @click="logout">
            <icon icon="cil-lock-locked" />
            {{ __('navigation.logout') }}
        </dropdown-item>
    </dropdown>
</template>

<script setup>
import Dropdown from '@/Components/Dropdown.vue';
import DropdownItem from '@/Components/DropdownItem.vue';
import { router } from '@inertiajs/vue3';

const logout = () => router.post(route('logout'));
</script>
