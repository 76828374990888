<template>
    <app-layout>
        <page-header :title="__('dashboard.headline')">
            <collapsable-filter-bar
                v-if="locations.length > 1"
                :active="activeFilters.locations.length > 0"
                :is-processing="isProcessingLocations"
                class="mt-2"
                @clear="activeFilters.locations = []"
            >
                <template #visible-filter>
                    <location-filter
                        v-model="activeFilters.locations"
                        :is-processing="isProcessingLocations"
                        style="width: auto"
                        @update:model-value="handleLocationFilterChange"
                    />
                </template>
            </collapsable-filter-bar>
        </page-header>
        <row>
            <column>
                <card>
                    <template #header>
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <updated-at :updated-at="lastUpdatedAt" />
                        </div>
                    </template>
                    <asset-management
                        :active-filters="activeFilters.usages"
                        :is-processing="activeFilters.processing"
                        @filter="handleAssetStatusFilterChange"
                        @selected="handleAssetTypeSelected"
                    />
                </card>
            </column>
        </row>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import LocationFilter from '@/Pages/Dashboard/Components/LocationFilter.vue';
import UpdatedAt from '@/Pages/Dashboard/Components/UpdatedAt.vue';
import AssetManagement from '@/Pages/Dashboard/Components/AssetManagement.vue';

import Card from '@/Components/Card.vue';
import PageTitle from '@/Components/PageTitle.vue';
import PageHeader from '@/Components/PageHeader.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';

export default {
    components: {
        CollapsableFilterBar,
        PageHeader,
        PageTitle,
        Card,

        AssetManagement,
        UpdatedAt,
        LocationFilter,
        AppLayout,
    },

    data() {
        return {
            isProcessingLocations: false,
            partialReloadTargets: [],
            activeFilters: this.$inertia.form({
                locations: [],
                usages: ['active'],
            }),
        };
    },

    computed: {
        locations() {
            return this.$page.props.locations.data.map((location) => ({
                value: location.iata_code,
                label: location.name,
                iata_code: location.iata_code,
            }));
        },
        lastUpdatedAt() {
            return this.$page.props.updatedAt;
        },
    },

    mounted() {
        this.readFiltersFromUrl();

        this.$watch(
            (vm) => [vm.activeFilters.locations, vm.activeFilters.usages],
            this.loadData,
            {
                immediate: false,
                deep: true,
            },
        );
    },

    methods: {
        loadData() {
            this.activeFilters
                .transform(this.transformData)
                .get(this.route('dashboard.index'), {
                    preserveState: true,
                    only: this.partialReloadTargets,
                    onFinish: () => {
                        this.isProcessingLocations = false;
                        this.partialReloadTargets = [];
                    },
                });
        },
        transformData(data) {
            data.locations = data.locations.join(',');
            data.usages = data.usages.join(',');

            if (!data.locations) {
                delete data.locations;
            }

            if (!data.usages) {
                delete data.usages;
            }
            return data;
        },
        handleAssetStatusFilterChange(filter) {
            this.partialReloadTargets = [
                'operableAssetsCount',
                'inoperableAssetsCount',
                'groupedAssetStatusByLocation',
            ];

            let idx = this.activeFilters.usages.indexOf(filter);
            if (idx === -1) {
                // Add filter if not present
                this.activeFilters.usages.push(filter);
            } else {
                if (this.activeFilters.usages.length === 1) {
                    // Ensure active filter is present if no filter is set
                    this.activeFilters.usages = ['active'];
                } else {
                    // Remove filter
                    this.activeFilters.usages.splice(idx, 1);
                }
            }
        },
        handleLocationFilterChange(locations) {
            this.partialReloadTargets = [];
            this.isProcessingLocations = true;
        },
        handleAssetTypeSelected(type) {
            this.$inertia.visit(
                this.route('assets.index', {
                    types: [type],
                    locations: this.activeFilters.locations,
                }),
            );
        },
        readFiltersFromUrl() {
            let params = this.route().params;
            let locations = Array.isArray(params.locations)
                ? params.locations
                : typeof params.locations === 'string'
                  ? params.locations.split(',')
                  : [];

            let allowedUsages = ['active', 'spare', 'decommissioned'];
            let usages = Array.isArray(params.usages)
                ? params.usages
                : typeof params.usages === 'string'
                  ? params.usages.split(',')
                  : [];

            usages = usages.filter((usage) => allowedUsages.includes(usage));

            this.activeFilters.locations = locations;
            this.activeFilters.usages = usages.length > 0 ? usages : ['active'];
        },
    },
};
</script>
