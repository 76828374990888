<template>
    <sticky-table-header :wrapper-style="stickyHeader">
        <div class="position-relative table-responsive">
            <table
                class="table table-striped table-hover table-bordered border"
            >
                <thead>
                    <tr>
                        <th
                            v-if="!!selectionEnabled"
                            style="
                                vertical-align: middle;
                                width: 32px;
                                min-width: 32px;
                                padding: 0;
                            "
                        >
                            <div
                                v-if="items.length"
                                v-c-tooltip="__('general.action.select_all')"
                                class="d-flex align-items-start justify-content-center"
                                style="height: 100%; cursor: pointer"
                                @click.stop="toggleAllSelection"
                            >
                                <input
                                    ref="selectAll"
                                    v-model="allSelected"
                                    :indeterminate="isIndeterminate"
                                    class="form-check-input m-0"
                                    type="checkbox"
                                />
                            </div>
                        </th>
                        <th
                            v-for="header in columns"
                            :style="[
                                header._style,
                                {
                                    cursor: isColumnSortable(header)
                                        ? 'pointer'
                                        : 'default',
                                },
                            ]"
                            class="position-relative text-nowrap"
                            @click="updateSorting(header)"
                        >
                            <div
                                class="d-flex justify-content-between align-items-center"
                                style="gap: 5px"
                            >
                                <span>{{ header.label }}</span>
                                <sort-icon
                                    v-if="isColumnSortable(header)"
                                    :state="getSortableColumnState(header)"
                                    style="position: relative; top: 2px"
                                />
                            </div>
                        </th>
                        <th
                            v-if="actions && actions.length && items.length"
                            style="width: 0px"
                        />
                    </tr>
                </thead>
                <tbody>
                    <template v-if="items.length === 0">
                        <tr>
                            <td :colspan="columnsLength">
                                <div class="text-center my-5">
                                    <h2
                                        class="d-flex align-items-center justify-content-center"
                                        style="gap: 5px"
                                    >
                                        {{ noItemsView.noItems }}
                                        <icon
                                            class="text-danger"
                                            icon="cil-ban"
                                            style="width: 30px; height: 30px"
                                        />
                                    </h2>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr
                            v-for="(row, index) in items"
                            :key="index"
                            :style="{
                                cursor: rowClickable ? 'pointer' : 'default',
                            }"
                            @click="rowClickable && emit('row-clicked', row)"
                        >
                            <td
                                v-if="selectionEnabled"
                                @click.stop="toggleItemSelection(row.id)"
                            >
                                <jet-checkbox
                                    :model-value="isSelectedItem(row.id)"
                                />
                            </td>
                            <template v-for="(column, idx) in columns">
                                <slot
                                    v-if="slots[column.key]"
                                    :key="idx"
                                    :item="row"
                                    :name="column.key"
                                    :style="column._style"
                                />
                                <td v-else class="align-middle">
                                    {{ row[column.key] }}
                                </td>
                            </template>
                            <td
                                v-if="actions && actions.length"
                                :class="actionClasses"
                            >
                                <div
                                    class="d-flex align-items-center justify-content-end"
                                    style="gap: 5px"
                                >
                                    <template
                                        v-for="action in actions"
                                        :key="action.icon"
                                    >
                                        <jet-button
                                            v-if="shouldRender(action, row)"
                                            :class="{ 'btn-icon': action.icon }"
                                            :color="action.type || 'secondary'"
                                            @click.stop="action.click(row)"
                                        >
                                            <icon
                                                v-if="action.icon"
                                                v-c-tooltip="
                                                    action.tooltip || ''
                                                "
                                                :icon="action.icon"
                                            />
                                            {{ action.label }}
                                        </jet-button>
                                    </template>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </sticky-table-header>
</template>
<script setup>
import StickyTableHeader from '@/Components/StickyTableHeader.vue';
import {
    computed,
    onMounted,
    onUnmounted,
    onUpdated,
    ref,
    useSlots,
} from 'vue';
import SortIcon from '@/Pages/Assets/SortIcon.vue';
import { router } from '@inertiajs/vue3';

import JetButton from '@/Jetstream/Button.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import { debounce } from 'lodash';
import { hasAnyPermission } from '@/mixins/hasAnyPermission.js';

const props = defineProps({
    columns: Array,
    items: Array,
    defaultSorting: Object,
    noItemsView: Object,
    actions: {
        type: Array,
        default: () => [],
    },
    rowClickable: {
        type: Boolean,
        default: true,
    },
    actionClasses: {
        type: [String, Object, Array],
        default: 'align-middle',
    },
    selectedItems: Array,
    selectionEnabled: {
        type: Boolean,
        default: false,
    },
    totalItemsCount: Number,
    sortQueryParam: {
        type: String,
        default: 'sort',
    },
    stickyHeader: {
        type: Object,
    },
});
const emit = defineEmits([
    'update:sorter-value',
    'row-clicked',
    'select:add',
    'select:remove',
]);
const slots = useSlots();
const sort = ref({ column: undefined, asc: true });
const selectedItems = ref([]);
const selectAll = ref(null);
const allSelected = ref(false);

const updateSorting = (column) => {
    // Column sorting needs to be switched off explicitly
    if (column.sorter === false) {
        return;
    }

    emit('update:sorter-value', {
        column: column.key,
        asc: column.key === sort.value.column ? !sort.value.asc : true,
    });
};

const columnsLength = computed(() => {
    let count = props.columns.length;
    if (props.actions && props.actions.length) {
        count += 1;
    }
    if (props.selectionEnabled) {
        count += 1;
    }
    return count;
});

const sortableColumns = computed(() =>
    props.columns.filter((column) => isColumnSortable(column)),
);

const isColumnSortable = (column) =>
    props.items.length && (column.sorter === undefined || !!column.sorter);

const getSortableColumnState = (column) => {
    if (isColumnSortable(column)) {
        if (sort.value.column === column.key) {
            return sort.value.asc ? 'asc' : 'desc';
        }
    }
    return 'none';
};

function getData(data, key, fallbackValue) {
    if (!data || !data.length || data[key] === undefined) {
        return fallbackValue;
    }

    return data[key];
}

function readSortingFromUrl() {
    // Read sorting from URL
    const sortString = route().params[props.sortQueryParam];

    if (typeof sortString !== 'string') {
        sort.value = {
            column: props.defaultSorting?.column,
            asc: props.defaultSorting?.asc === true,
        };

        return;
    }

    const sortParts = (sortString || '').split('.').filter((part) => !!part);
    const sortColumn = getData(sortParts, 0, props.defaultSorting?.column);
    const sortDirection = getData(
        sortParts,
        1,
        props.defaultSorting?.asc ? 'asc' : 'desc',
    );

    // Check if sorting column is allowed
    const sortableColumnNames = sortableColumns.value.map(
        (column) => column.key,
    );
    if (!sortableColumnNames.includes(sortColumn)) {
        return;
    }

    sort.value = {
        column: sortColumn,
        asc: sortDirection === 'asc',
    };
}

// Row selection

const isIndeterminate = computed(
    () =>
        props.selectedItems?.length > 0 &&
        props.selectedItems.length < props.totalItemsCount,
);

const isSelectedItem = function (id) {
    return props.selectedItems?.includes(id);
};

function toggleItemSelection(id) {
    if (isSelectedItem(id)) {
        emit('select:remove', id);
    } else {
        emit('select:add', id);
    }
}

const toggleAllSelection = debounce(function () {
    if (allSelected.value) {
        emit('select:add', '*');
    } else {
        emit('select:remove', '*');
    }
}, 150);

function shouldRender(action, item) {
    let shouldRender = true;

    if (action.permissions && !hasAnyPermission(action.permissions)) {
        shouldRender = false;
    }

    if (action.condition && !action.condition(item)) {
        shouldRender = false;
    }

    return shouldRender;
}

// Hooks

onUpdated(() => {
    readSortingFromUrl();
    allSelected.value = props.selectedItems?.length === props.totalItemsCount;
});

onMounted(readSortingFromUrl);
onUnmounted(router.on('finish', readSortingFromUrl));
</script>
