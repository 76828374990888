<template>
    <div
        ref="container"
        :class="{ 'c-show': show }"
        class="multi-select date-filter"
        style="user-select: none; cursor: pointer"
        tabindex="0"
        @click="toggleVisibility"
        @keydown.esc="show = false"
    >
        <span
            class="multi-select-selection d-flex justify-content-between align-items-center"
            style="min-height: 2rem"
        >
            <span v-if="!modelValue && label">{{ label }}</span>
            <span v-else class="text-truncate text-nowrap">{{
                $filters.fullDate(modelValue, currentLocale)
            }}</span>
        </span>
        <div class="multi-select-options" style="width: auto" @click.stop="">
            <calendar
                ref="calendar"
                :attributes="appliedAttributes"
                :disabled-dates="disabledDates"
                :first-day-of-week="2"
                :locale="currentLocale"
                is-expanded
                trim-weeks
                @dayclick="onDateSelect"
            >
                <template #day-popover="{ day, dayEvents }">
                    <div>
                        <div>
                            {{ $filters.fullDate(day.date, currentLocale) }}
                        </div>
                        <div v-for="obj in day.attributes">
                            <span v-if="obj.popover">{{
                                obj.popover.label
                            }}</span>
                        </div>
                    </div>
                </template>
            </calendar>
        </div>
    </div>
</template>
<script>
import { Calendar } from 'v-calendar';
import { DateTime } from 'luxon';
import { onClickOutside } from '@vueuse/core';

export default {
    name: 'date-filter',
    computed: {
        appliedAttributes() {
            return this.attributes && this.attributes.length
                ? this.attributes
                : [
                      {
                          key: 'selected',
                          highlight: true,
                          dates: this.modelValue,
                      },
                  ];
        },
    },
    components: { Calendar },
    props: {
        modelValue: String,
        attributes: Array,
        disabledDates: {
            type: Array,
            default: () => [],
        },
        selectionMode: {
            type: String,
            default: 'daily',
        },
        label: String,
    },
    watch: {
        modelValue: {
            immediate: true,
            handler(value) {
                // Necessary to switch to correct month
                // if date is in prev/next month
                if (!value) {
                    return;
                }
                this.$refs.calendar?.focusDate(new Date(value));
            },
        },
    },
    data() {
        return {
            show: false,
        };
    },
    mounted() {
        onClickOutside(this.$refs.container, this.hideElement);
    },
    methods: {
        hideElement() {
            this.show = false;
        },
        toggleVisibility() {
            if (this.disabled) {
                this.show = false;
            }
            this.show = !this.show;
        },
        onDateSelect(event) {
            let date = DateTime.fromJSDate(event.date);
            if (!!event.isDisabled) {
                return;
            }

            if (this.selectionMode === 'weekly') {
                date = date.endOf('week');
            }

            this.$emit('update:modelValue', date.toFormat('yyyy-MM-dd'));
        },
    },
};
</script>
