<template>
    <c-alert v-if="hasFlashMessage" :color="color">
        <dynamic-content :content="content" />
    </c-alert>
</template>
<script>
import { compile, h } from 'vue';

// Component to render dynamic templates
const DynamicContent = {
    props: ['content'],
    render: function () {
        return h(compile(this.content));
    },
};

export default {
    components: {
        DynamicContent,
    },
    computed: {
        color() {
            return this.isSuccessMessage ? 'success' : 'danger';
        },
        flash() {
            return this.$page.props.flash;
        },
        isSuccessMessage() {
            return (
                this.flash &&
                this.flash.message &&
                this.flash.message.length > 0
            );
        },
        isErrorMessage() {
            return (
                this.flash && this.flash.error && this.flash.error.length > 0
            );
        },
        content() {
            const message = this.isSuccessMessage
                ? this.flash.message
                : this.flash.error;
            return '<div>' + message + '</div>';
        },
        hasFlashMessage() {
            return this.isSuccessMessage || this.isErrorMessage;
        },
    },
};
</script>
