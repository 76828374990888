<template>
    <div class="d-flex" style="gap: 10px">
        <Link
            :href="cancelRoute"
            class="btn btn-secondary"
            v-text="__('general.action.cancel')"
        />
        <jet-button
            :disabled="disabled"
            :is-processing="isProcessing"
            color="primary"
        >
            <span v-if="isUpdateForm" v-text="__('general.action.save')" />
            <span v-else v-text="__('general.action.create')" />
        </jet-button>
    </div>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        isUpdateForm: {
            type: Boolean,
            default: false,
        },
        cancelRoute: String,
        isProcessing: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        JetButton,
    },
};
</script>
