<template>
    <TheContainer>
        <alert v-if="isDemo" color="danger">
            !!! DEMO INSTANCE - RESETS EVERY 24 HOURS !!!
        </alert>
        <slot />
        <!-- Modals used in the application -->
        <petitec-id-scanner />
        <!-- End of Modals -->
    </TheContainer>
</template>

<script>
import JetApplicationMark from '@/Jetstream/ApplicationMark.vue';
import JetBanner from '@/Jetstream/Banner.vue';
import JetDropdown from '@/Jetstream/Dropdown.vue';
import JetDropdownLink from '@/Jetstream/DropdownLink.vue';
import JetNavLink from '@/Jetstream/NavLink.vue';
import JetResponsiveNavLink from '@/Jetstream/ResponsiveNavLink.vue';
import TheContainer from '@/Containers/TheContainer.vue';
import FlashMessage from '@/Components/FlashMessage.vue';
import PetitecIdScanner from '@/Components/PetitecIdScanner.vue';
import Alert from '@/Components/Alert.vue';

export default {
    components: {
        Alert,
        PetitecIdScanner,
        JetApplicationMark,
        JetBanner,
        JetDropdown,
        JetDropdownLink,
        JetNavLink,
        JetResponsiveNavLink,
        TheContainer,
        FlashMessage,
    },

    props: {
        enableFlashMessage: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            showingNavigationDropdown: false,
        };
    },

    computed: {
        isDemo() {
            return this.$page.props.is_demo ?? false;
        },
    },

    methods: {
        switchToTeam(team) {
            this.$inertia.put(
                route('current-team.update'),
                {
                    team_id: team.id,
                },
                {
                    preserveState: false,
                },
            );
        },

        logout() {
            this.$inertia.post(route('logout'));
        },
    },
};
</script>
