<template>
    <div>
        <card>
            <template #header>
                <custom-card-header
                    :href="$route('assets.tests.index', asset)"
                    :label="__('general.action.show_all')"
                    :show-action="hasActiveFilters"
                    :title="__('test_records.overview.header')"
                />
            </template>
            <row v-if="isTestOverdue">
                <column>
                    <alert
                        class="d-flex align-items-center"
                        color="danger"
                        style="gap: 5px; line-height: 1rem"
                    >
                        <exclamation-mark type="filled" />
                        <div style="line-height: 1rem">
                            <span>{{
                                __('test.overview.alert.overdue_test')
                            }}</span
                            >&nbsp;
                            <span v-if="readableCadence">{{
                                __('test.overview.alert.overdue_test.cadence', {
                                    cadence: readableCadence,
                                })
                            }}</span>
                        </div>
                    </alert>
                </column>
            </row>
            <row>
                <column>
                    <data-table
                        v-if="records"
                        :actions="actions"
                        :columns="fields"
                        :default-sorting="{ column: 'date', asc: false }"
                        :items="records.data"
                        :no-items-view="noItemsView"
                        @row-clicked="toggleReport"
                        @update:sorter-value="updateSorting"
                    >
                        <template #date="{ item }">
                            <td class="align-top">
                                <div class="text-muted">
                                    <div class="text-nowrap">
                                        {{
                                            $filters.fullDate(
                                                item.date,
                                                currentLocale,
                                            )
                                        }}
                                    </div>
                                    <small>
                                        <icon icon="cil-clock" />
                                        {{
                                            $filters.time(
                                                item.date,
                                                currentLocale,
                                            )
                                        }}</small
                                    >
                                </div>
                            </td>
                        </template>

                        <template #user="{ item }">
                            <td class="align-top">
                                <user-information
                                    v-if="item.user"
                                    :info="item.user"
                                />
                                <span v-else>-</span>
                            </td>
                        </template>

                        <template #result="{ item }">
                            <td class="align-middle text-center">
                                <icon
                                    v-if="item.resolution.value === 'success'"
                                    :key="item.id"
                                    v-c-tooltip="{
                                        content: __(
                                            'test_records.overview.tooltip.result.successful',
                                        ),
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                    icon="cil-check-alt"
                                    size="lg"
                                />
                                <icon
                                    v-else
                                    :key="item.id"
                                    v-c-tooltip="{
                                        content: __(
                                            'test_records.overview.tooltip.result.failed',
                                        ),
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                    class="text-danger"
                                    icon="cil-warning"
                                />
                            </td>
                        </template>

                        <template #report="{ item }">
                            <td class="align-top">
                                <div
                                    :class="{
                                        'text-max-2-lines':
                                            !showFullDescription(item),
                                    }"
                                    class="text-break"
                                    style="-webkit-line-clamp: 3"
                                >
                                    {{ item.description }}
                                </div>
                            </td>
                        </template>

                        <template #attachments="{ item }">
                            <td class="align-top">
                                <FileList
                                    :attachments="item.attachments"
                                    :can-read="
                                        hasAnyPermission([
                                            'test.attachment.index',
                                        ])
                                    "
                                    :inline="true"
                                    :readonly="true"
                                />
                            </td>
                        </template>

                        <template #qr_code_scanned="{ item }">
                            <td class="align-middle text-center">
                                <icon
                                    v-if="item.qr_code_scanned_at"
                                    v-c-tooltip="{
                                        content: __(
                                            'test_records.overview.tooltip.qr_code_scanned',
                                        ),
                                    }"
                                    icon="cil-check-alt"
                                    size="lg"
                                />
                                <icon
                                    v-else
                                    v-c-tooltip="{
                                        content: __(
                                            'test_records.overview.tooltip.qr_code_not_scanned',
                                        ),
                                    }"
                                    icon="cil-x"
                                    size="lg"
                                />
                            </td>
                        </template>
                    </data-table>
                </column>
            </row>
        </card>
        <jet-confirmation-modal
            :show="showConfirmDeletionDialog"
            type="danger"
            @close="recordToDelete = null"
        >
            <template #title>
                {{ __('test_records.overview.confirmation.delete.title') }}
            </template>

            <template #content>
                {{ __('test_records.overview.confirmation.delete.content') }}
            </template>

            <template #footer>
                <slot name="footer">
                    <jet-button
                        :isProcessing="isDeletingTestRecord"
                        color="danger"
                        @click.stop="deleteTestRecord(recordToDelete)"
                    >
                        {{ __('general.action.delete') }}
                    </jet-button>
                </slot>
            </template>
        </jet-confirmation-modal>
    </div>
</template>
<script>
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import ConfirmedDelete from '@/Components/ConfirmedDelete.vue';
import FileList from '@/Components/FileList.vue';
import UserInformation from '@/Components/UserInformation.vue';
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import DataTable from '@/Components/DataTable.vue';
import Card from '@/Components/Card.vue';
import Alert from '@/Components/Alert.vue';
import Badge from '@/Components/Badge.vue';
import ExclamationMark from '../../icons/ExclamationMark.vue';

export default {
    components: {
        ExclamationMark,
        Badge,
        Alert,
        Card,
        DataTable,
        JetButton,
        JetConfirmationModal,
        CustomCardHeader,
        ConfirmedDelete,
        FileList,
        UserInformation,
    },

    data() {
        return {
            openReports: [],
            fields: [
                {
                    key: 'date',
                    label: this.__('test_records.overview.cell.date'),
                },
                {
                    key: 'result',
                    label: this.__('test_records.overview.cell.result'),
                    sorter: false,
                },
                {
                    key: 'report',
                    label: this.__('test_records.overview.cell.report'),
                    sorter: false,
                },
                {
                    key: 'attachments',
                    label: this.__('test_records.overview.cell.attachments'),
                    sorter: false,
                },
                {
                    key: 'user',
                    label: this.__('test_records.overview.cell.user'),
                    sorter: false,
                },
                {
                    key: 'qr_code_scanned',
                    label: this.__(
                        'test_records.overview.cell.qr_code_scanned',
                    ),
                },
            ],
            noItemsView: {
                noItems: this.__('test_records.overview.results.empty'),
            },
            selectedActivity: null,
            showActivityDetails: false,
            transformers: {
                scheduled_for: (value) => {
                    return this.$filters.fullDate(value, this.currentLocale);
                },
            },
            actions: [
                {
                    permissions: ['test.delete'],
                    tooltip: this.__('general.action.delete'),
                    type: 'danger',
                    click: this.setRecordToDelete,
                    icon: 'cil-trash',
                },
            ],
            recordToDelete: null,
            isDeletingTestRecord: false,
        };
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
        records() {
            return this.$page.props.tests;
        },
        hasActiveFilters() {
            return this.$page.props.is_filter_active;
        },
        isTestOverdue() {
            return this.$page.props.isTestOverdue;
        },
        cadenceConfig() {
            return this.$page.props.cadenceConfig;
        },
        readableCadence() {
            return !!this.cadenceConfig
                ? `${this.cadenceConfig.value} ${this.cadenceConfig.unit}`
                : null;
        },
        showConfirmDeletionDialog() {
            return !!this.recordToDelete;
        },
    },

    methods: {
        deleteTestRecord(item) {
            if (!item) {
                return;
            }

            this.isDeletingTestRecord = true;

            this.$inertia.delete(
                this.route('assets.tests.destroy', {
                    asset: this.asset,
                    test: item,
                }),
                {
                    preserveState: true,
                    onSuccess: () => {
                        this.recordToDelete = null;
                    },
                    onFinish: () => {
                        this.isDeletingTestRecord = false;
                    },
                },
            );
        },
        showFullDescription(item) {
            return this.openReports.includes(item.id);
        },
        toggleReport(item) {
            if (this.openReports.includes(item.id)) {
                this.openReports = this.openReports.filter(
                    (id) => id !== item.id,
                );
            } else {
                this.openReports.push(item.id);
            }
        },
        updateSorting(event) {
            let sort = `${event.column}.${event.asc ? 'asc' : 'desc'}`;

            this.$inertia.get(this.route('assets.tests.index', this.asset), {
                sort,
            });
        },
        setRecordToDelete(item) {
            this.recordToDelete = item;
        },
    },
};
</script>
