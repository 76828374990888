<template>
    <c-modal
        :closeOnBackdrop="closeable"
        :scrollable="scrollable"
        :size="maxWidth"
        :visible="show"
        backdrop="static"
        @close="$emit('close', $event)"
        @show="onShow"
    >
        <c-modal-header
            :class="headerClasses"
            :close-button="false"
            class="flex-column align-items-start"
        >
            <c-modal-title>
                <slot name="title" />
            </c-modal-title>
            <div v-if="$slots.subtitle">
                <slot name="subtitle" />
            </div>
        </c-modal-header>
        <c-modal-body>
            <slot />
        </c-modal-body>
        <c-modal-footer>
            <slot name="footer">
                <div
                    class="w-100 d-flex justify-content-center align-items-center"
                    style="gap: 10px"
                >
                    <jet-button
                        :disabled="isProcessing || cancelDisabled"
                        color="secondary"
                        @click.stop="$emit('close', $event)"
                    >
                        {{
                            secondaryButtonLabel || __('general.action.cancel')
                        }}
                    </jet-button>
                    <jet-button
                        :disabled="isProcessing || submitDisabled"
                        :is-processing="isProcessing"
                        @click.stop="$emit('submit', $event)"
                    >
                        {{ primaryButtonText }}
                    </jet-button>
                </div>
            </slot>
        </c-modal-footer>
    </c-modal>
</template>

<script>
import {
    CModal,
    CModalTitle,
} from '@coreui/vue-pro/dist/esm/components/modal/index.js';
import JetButton from '@/Jetstream/Button.vue';
import Spinner from '@/Components/Spinner.vue';

export default {
    name: 'DialogModal',

    emits: ['close', 'submit'],

    components: {
        Spinner,
        JetButton,
        CModalTitle,
        CModal,
    },

    props: {
        show: {
            default: false,
        },
        maxWidth: {
            default: undefined,
        },
        closeable: {
            default: false,
        },
        scrollable: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: 'light',
        },
        primaryButtonLabel: String,
        secondaryButtonLabel: String,
        isProcessing: {
            type: Boolean,
            default: false,
        },
        cancelDisabled: {
            type: Boolean,
            default: false,
        },
        submitDisabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        headerClasses() {
            return {
                'bg-blue-light': this.type === 'light',
                'bg-danger text-white': this.type === 'danger',
            };
        },
        primaryButtonText() {
            return this.primaryButtonLabel || this.__('general.action.save');
        },
    },

    methods: {
        onShow() {
            // Remove all currently displayed popovers that might potentially
            // overlay the open modal
            let elements = document.getElementsByClassName('popover');
            while (elements.length > 0) {
                elements[0].parentNode.removeChild(elements[0]);
            }
        },
    },

    beforeUnmount() {
        document.body.classList.remove('modal-open');
        document.body.style.padding = null;
        document.body.style.overflow = null;
    },
};
</script>
