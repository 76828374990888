<template>
    <app-layout>
        <row>
            <column md="6">
                <jet-form-section @submitted="handleSubmit($event)">
                    <template #title>
                        <div v-if="isUpdateForm">
                            {{
                                __('airports.edit_airport.title', {
                                    name: form.name,
                                })
                            }}
                        </div>
                        <div v-else>
                            {{ __('airports.create_airport.title') }}
                        </div>
                    </template>

                    <template #form>
                        <jet-input
                            v-model="form.name"
                            :autofocus="true"
                            :invalidFeedback="form.errors.name"
                            :label="
                                __('airports.edit_airport.field.name.label')
                            "
                            :required="true"
                        />
                        <jet-input
                            v-model="form.icao_code"
                            :invalidFeedback="form.errors.icao_code"
                            :label="
                                __('airports.edit_airport.field.icao.label')
                            "
                            :required="true"
                        />
                        <jet-input
                            v-model="form.iata_code"
                            :invalidFeedback="form.errors.iata_code"
                            :label="
                                __('airports.edit_airport.field.iata.label')
                            "
                            :required="true"
                        />
                        <jet-input
                            v-model="form.country_code_alpha2"
                            :invalidFeedback="form.errors.country_code_alpha2"
                            :label="
                                __(
                                    'airports.edit_airport.field.country.alpha2.label',
                                )
                            "
                            :required="true"
                        />
                        <jet-input
                            v-model="form.country_code_alpha3"
                            :invalidFeedback="form.errors.country_code_alpha3"
                            :label="
                                __(
                                    'airports.edit_airport.field.country.alpha3.label',
                                )
                            "
                            :required="true"
                        />
                        <jet-select
                            v-model="form.timezone"
                            :enable-search="true"
                            :invalidFeedback="form.errors.timezone"
                            :label="
                                __('airports.edit_airport.field.timezone.label')
                            "
                            :options="timezones"
                            :placeholder="
                                __(
                                    'airports.edit_airport.field.timezone.placeholder',
                                )
                            "
                            :required="true"
                        />
                        <multi-select-dropdown
                            v-model="form.users"
                            :disabled="!users.length"
                            :invalid-feedback="form.errors.users"
                            :is-form-field="true"
                            :label="
                                __('airports.edit_airport.field.users.label')
                            "
                            :options="users"
                            :placeholder="
                                __(
                                    'airports.edit_airport.field.users.placeholder',
                                )
                            "
                            :show-searchbar="true"
                        />
                    </template>

                    <template #actions>
                        <JetFormButtons
                            :cancel-route="$route('airports.index')"
                            :disabled="!isFormSubmittable"
                            :is-processing="form.processing"
                            :is-update-form="isUpdateForm"
                        />
                    </template>
                </jet-form-section>
            </column>
        </row>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetSelect from '@/Jetstream/Select.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetFormButtons from '@/Jetstream/FormButtons.vue';
import { Timezone } from '@/Utils/timezone';
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import { toRaw } from 'vue';

export default {
    components: {
        MultiSelectDropdown,
        AppLayout,
        JetFormButtons,
        JetInput,
        JetFormSection,
        JetSelect,
    },

    data() {
        return {
            form: this.$inertia.form({
                name: null,
                iata_code: null,
                icao_code: null,
                country_code_alpha2: null,
                country_code_alpha3: null,
                timezone: null,
                users: [],
            }),
        };
    },

    computed: {
        airport() {
            return this.$page.props.airport;
        },
        users() {
            return this.$page.props.users.data.map((user) => ({
                label: user.name,
                value: user.id,
            }));
        },
        timezones() {
            return this.$page.props.timezones.data.map((timezone) => {
                let shortName = new Timezone(timezone.identifier).shortName;

                let label = `
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="text-truncate"">${timezone.name}</div>
                        <small class="text-muted">${shortName}</small>
                    </div>
                `;
                return {
                    label: label,
                    value: timezone.identifier,
                };
            });
        },
        isUpdateForm() {
            return !!this.airport;
        },
    },

    mounted() {
        if (this.isUpdateForm) {
            this.form = this.$inertia.form({
                name: this.airport.name,
                iata_code: this.airport.iata_code,
                icao_code: this.airport.icao_code,
                country_code_alpha2: this.airport.country_code_alpha2,
                country_code_alpha3: this.airport.country_code_alpha3,
                timezone: this.airport.timezone.identifier,
                users: this.airport.users.map((user) => user.id),
            });
        }
    },

    methods: {
        handleSubmit(event) {
            if (this.isUpdateForm) {
                this.updateAirport();
            } else {
                this.createAirport();
            }
        },
        createAirport() {
            this.form
                .transform(this.transformData)
                .post(this.route('airports.store'));
        },
        updateAirport() {
            this.form.transform(this.transformData).put(
                this.route('airports.update', {
                    airport: this.airport,
                }),
            );
        },
        transformData(data) {
            return {
                ...data,
                users: toRaw(data.users) || [],
            };
        },
        getCancelRoute() {
            return this.isUpdateForm
                ? this.route('airports.show', this.airport.id)
                : this.route('airports.index');
        },
    },
};
</script>
