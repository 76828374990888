import Echo from 'laravel-echo';
import { Connector } from './Websockets/Connector';

let $echo = null;

export function setupEcho(config) {
    if (!!$echo) {
        return;
    }

    if (!config.host) {
        throw "Parameter 'host' is missing!";
    }

    $echo = new Echo({
        broadcaster: Connector,
        host: config.host,
        enabledTransports: ['wss'],
    });
}

export function subscribe(channel, callback) {
    if (!channel || !callback) {
        return;
    }

    if (!$echo) {
        throw 'Echo is not initialized!';
    }

    $echo.private(channel).notification(callback);
}
