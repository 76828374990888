<template>
    <span :key="state">
        <svg
            v-c-tooltip="tooltip"
            :class="`sort-order-${state}`"
            class="sort-order flex-shrink-0 flex-grow-0"
            height="8px"
            viewBox="0 0 14 8"
            width="14px"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill="#ccc" stroke="#ccc" transform="translate(-620, -292)">
                <g transform="translate(281, 191)">
                    <path
                        d="M346,101.199609 L351.040312,107.5 L340.959688,107.5 L346,101.199609 Z"
                        transform="translate(346, 104.5) scale(1, -1) translate(-346, -104.5)"
                    />
                </g>
            </g>
        </svg>
    </span>
</template>
<script>
export default {
    name: 'sort-icon',
    props: ['state'],
    computed: {
        tooltip() {
            let key = this.state || 'none';
            return this.__(`general.sortorder.${key}`);
        },
    },
};
</script>
