<template>
    <div
        v-if="locations.length > 1"
        class="d-sm-flex align-items-center"
        style="width: 100%"
    >
        <multi-select-dropdown
            :disabled="disabled"
            :label="__('dashboard.filter.locations')"
            :model-value="modelValue"
            :options="locations"
            :show-searchbar="true"
            :show-select-all-button="false"
            @update:model-value="$emit('update:modelValue', $event)"
        >
            <template v-slot:label="{ option }">
                <div class="d-flex justify-content-between align-items-center">
                    <span class="mr-3">{{ option.label }}</span
                    ><small class="text-muted">{{ option.iata_code }}</small>
                </div>
            </template>
        </multi-select-dropdown>
    </div>
</template>
<script>
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';

export default {
    components: { MultiSelectDropdown },
    emits: ['update:modelValue'],
    props: {
        isProcessing: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        locations() {
            return (
                this.$page.props.locations?.data.map((loc) => ({
                    label: loc.name,
                    value: loc.iata_code,
                    iata_code: loc.iata_code,
                    searchableContent: `${loc.iata_code}|${loc.icao_code}|${loc.name}`,
                })) || []
            );
        },
    },
};
</script>
