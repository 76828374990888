<template>
    <div>
        <dropdown-with-action
            :dependentOn="manufacturer"
            :horizontal="false"
            :invalidFeedback="invalidFeedback"
            :label="__('assets.details.field.designation.label')"
            :model-value="modelValue"
            :optionTransformer="transformOption"
            :options="[]"
            :placeholder="__('assets.details.field.designation.placeholder')"
            :required="true"
            :routes="{
                index: route('models.index', { manufacturer }),
            }"
            :showAction="hasAnyPermission('model.create')"
            @open="isModalVisible = true"
            @update:model-value="$emit('update:model-value', $event)"
        >
            <template #description>
                <div v-if="!manufacturer">
                    <small
                        class="form-text text-muted w-100"
                        v-text="__('locations.field.designation.description')"
                    />
                </div>
            </template>
            <template #actions>
                <button-add-new
                    v-if="hasAnyPermission('model.create') && manufacturer"
                    @click.native="addNewModel"
                >
                    {{ __('models.actions.add_designation') }}
                </button-add-new>
            </template>

            <template #modal="{ modal }">
                <create-model-modal
                    v-if="hasAnyPermission('model.create')"
                    :manufacturer_id="manufacturer"
                    :show="isModalVisible"
                    :types="types"
                    @cancel="isModalVisible = false"
                    @success="handleCreateModelSuccess(modal, $event)"
                />
            </template>
        </dropdown-with-action>
    </div>
</template>
<script>
import CreateModelModal from '@/Pages/Assets/Modals/CreateModelModal.vue';
import DropdownWithAction from '@/Components/DropdownWithAction.vue';
import ButtonAddNew from '@/Components/ButtonAddNew.vue';

export default {
    props: {
        invalidFeedback: String,
        modelValue: String,
        manufacturer: String,
    },
    components: {
        ButtonAddNew,
        CreateModelModal,
        DropdownWithAction,
    },
    data() {
        return {
            options: [],
            isLoading: false,
            isModalVisible: false,
        };
    },
    computed: {
        types() {
            return this.$page.props.types.data;
        },
    },
    methods: {
        handleCreateModelSuccess(modal, data) {
            modal.loadOptions(data.id);
            this.isModalVisible = false;
        },
        transformOption(option) {
            return {
                value: option.id,
                label: `<div
                class="d-flex justify-content-between align-items-center"
                style="max-width: 100%; overflow: hidden"
            >
                <div style="text-overflow: ellipsis; overflow: hidden">
                    ${option.designation}
                </div>
                <small class="text-muted">${option.type}</small>
            </div>`,
            };
        },
    },
};
</script>
