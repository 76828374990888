<template>
    <div>
        <div
            ref="sticky"
            :style="wrapperStyle"
            aria-hidden="true"
            class="sticky-header-wrapper"
        >
            <slot />
        </div>
        <div ref="orig" class="sticky-header-orig">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'StickyTableHeader',
    props: { wrapperStyle: Object },
    mounted() {
        const el =
            this.$refs.orig.getElementsByClassName('table-responsive')?.[0];
        const sticky =
            this.$refs.sticky.getElementsByClassName('table-responsive')?.[0];

        el?.addEventListener('scroll', this.onScrollEl);
        sticky?.addEventListener('scroll', this.onScrollSticky);
    },
    beforeDestroy() {
        const el =
            this.$refs.orig.getElementsByClassName('table-responsive')?.[0];
        const sticky =
            this.$refs.sticky.getElementsByClassName('table-responsive')?.[0];

        el?.removeEventListener('scroll', this.scrollSticky);
        sticky?.removeEventListener('scroll', this.scrollEl);
    },
    methods: {
        onScrollSticky(event) {
            let el =
                this.$refs.orig.getElementsByClassName('table-responsive')?.[0];
            el.scrollLeft = event.target.scrollLeft;
        },
        onScrollEl(event) {
            let el =
                this.$refs.sticky?.getElementsByClassName(
                    'table-responsive',
                )?.[0];
            el.scrollLeft = event.target.scrollLeft;
        },
    },
};
</script>

<style scoped>
.sticky-header-wrapper {
    position: sticky;
    top: 56px;
    border-top: 16px solid #eaedef;
    overflow: hidden;
    height: 57px;
    z-index: 1;
}

@media (max-width: 576px) {
    .sticky-header-wrapper {
        top: 56px !important;
    }
}

.card-body .sticky-header-wrapper {
    border-top-color: #fff;
}

.sticky-header-orig {
    margin-top: -41px;
}
</style>
