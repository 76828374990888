<template>
    <dialog-modal
        v-if="
            shouldRender && hasAnyPermission('physical_location.child.create')
        "
        :is-processing="form.processing"
        :primary-button-label="__('general.action.create')"
        :show="show"
        :submit-disabled="!form.isDirty"
        @close="closeModal"
        @submit="handleSubmit"
    >
        <template #title
            >{{ __('locations.modal.add_specific_location.title') }}
        </template>

        <form @submit.prevent="handleSubmit">
            <row>
                <column>
                    <div class="d-flex w-100">
                        <jet-input
                            v-model="form.name"
                            :autofocus="true"
                            :invalidFeedback="form.errors.name"
                            :label="
                                __('locations.field.specific_location.label')
                            "
                            :placeholder="
                                __('locations.field.specific_location.label')
                            "
                            :required="true"
                            style="flex-grow: 1"
                        />
                    </div>
                </column>
            </row>
        </form>
    </dialog-modal>
</template>
<script>
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetSelect from '@/Jetstream/Select.vue';
import { eventBus, events } from '@/eventBus.js';
import { $http } from '@/bootstrap.js';

export default {
    components: { DialogModal, JetButton, JetInput, JetSelect },
    data() {
        return {
            shouldRender: false,
            show: false,
            physical_location_id: null,
            form: this.$inertia.form({
                name: null,
            }),
        };
    },

    mounted() {
        eventBus.$on(events.openCreateSpecificLocationModal, (config) => {
            if (!config?.physicalLocationId) {
                return;
            }

            this.physical_location_id = config.physicalLocationId;
            this.showModal();
        });
    },

    methods: {
        showModal() {
            this.form.reset();
            this.form.clearErrors();
            this.shouldRender = true;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        closeModal(event) {
            event?.preventDefault();

            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
            }, 300);
        },
        handleSubmit() {
            this.form.processing = true;

            $http
                .post(
                    route(
                        'airports.specific-locations.store',
                        this.physical_location_id,
                    ),
                    Object.assign({}, this.form.data()),
                )
                .then((response) => {
                    this.closeModal();
                    this.$emit('success', response.data);
                })
                .catch((error) => {
                    this.form.clearErrors();
                    let errors = {};

                    Object.keys(error.response.data.errors).map((key) => {
                        errors[key] = error.response.data.errors[key][0];
                    });

                    this.form.errors = errors;
                })
                .finally(() => {
                    this.form.processing = false;
                });
        },
    },
};
</script>
