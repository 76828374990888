<template>
    <app-layout>
        <asset-details-header />
        <div class="py-3">
            <ActivityLog
                :activities="activities"
                :fields="fields"
                context="asset"
            />
            <pagination :paginator="activities" />
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import AssetDetailsHeader from '@/Pages/Assets/Details/AssetDetailsHeader.vue';
import ActivityLog from '@/Components/ActivityLog/ActivityLog.vue';
import Pagination from '@/Components/Pagination.vue';

export default {
    components: {
        Pagination,
        AppLayout,
        AssetDetailsHeader,
        ActivityLog,
    },

    data() {
        return {
            fields: [
                {
                    key: 'date',
                    label: this.__('activities.overview.cell.date'),
                    sorter: false,
                },
                {
                    key: 'causer',
                    label: this.__('activities.overview.cell.user'),
                    sorter: false,
                },
                {
                    key: 'event',
                    label: this.__('activities.overview.cell.event'),
                    sorter: false,
                },
            ],
        };
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
        activities() {
            return this.$page.props.activities;
        },
    },
};
</script>
